import React, { useState } from "react";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { login, setLoggedIn } from "../../store/slice/auth";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik } from "formik";

const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await dispatch(login(values)).unwrap();
      console.log("companyName", response?.data?.apiresponse?.data?.compnyName);

      dispatch(setLoggedIn(true));
      sessionStorage.setItem("isLoggedIn", "true");
      sessionStorage.setItem("email", values.email);
      if (response?.data?.apiresponse?.data?.compnyName) {
        sessionStorage.setItem(
          "compnyName",
          response.data.apiresponse.data.compnyName
        );
      }
      resetForm();
      navigate("/");
      enqueueSnackbar("You have successfully logged in.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("errorerror", error);

      resetForm();
      navigate("/login");
      const errorMessage =
        error?.response?.data?.apierror?.message ||
        "An error occurred during login";
      enqueueSnackbar(
        errorMessage ===
          "Email or password is invaliad please register if you're not"
          ? "Invalid Credentials"
          : errorMessage,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <div className="login-section">
      <div className="login-parts">
        <div className="cards max-w-[700px]">
          <a href="/">
            <div className="flex items-center gap-3 mb-5 md:mb-10 border-primary border w-fit py-1 px-3 rounded-10">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 12C2.25 17.3845 6.61547 21.75 12 21.75C17.3845 21.75 21.75 17.3845 21.75 12C21.75 6.61547 17.3845 2.25 12 2.25C6.61547 2.25 2.25 6.61547 2.25 12ZM12.218 7.71749C12.2879 7.78688 12.3435 7.86933 12.3815 7.96021C12.4196 8.05104 12.4394 8.14853 12.4398 8.24704C12.4402 8.34555 12.4212 8.44315 12.3838 8.53429C12.3465 8.62544 12.2916 8.70837 12.2222 8.77829L9.76971 11.25H16.0313C16.2302 11.25 16.4209 11.329 16.5616 11.4697C16.7022 11.6103 16.7812 11.8011 16.7812 12C16.7812 12.1989 16.7022 12.3897 16.5616 12.5303C16.4209 12.671 16.2302 12.75 16.0313 12.75H9.76971L12.2222 15.2217C12.2916 15.2917 12.3465 15.3747 12.3838 15.4659C12.4211 15.5571 12.4401 15.6548 12.4396 15.7533C12.4392 15.8518 12.4194 15.9493 12.3812 16.0402C12.3431 16.1311 12.2875 16.2136 12.2175 16.283C12.1475 16.3523 12.0645 16.4073 11.9733 16.4446C11.8821 16.4819 11.7844 16.5009 11.6859 16.5004C11.5874 16.5 11.4899 16.4802 11.399 16.442C11.3081 16.4039 11.2256 16.3483 11.1563 16.2783L7.43483 12.5283C7.29547 12.3878 7.21723 12.1979 7.21723 12C7.21723 11.8021 7.29547 11.6122 7.43483 11.4717L11.1563 7.72171C11.2257 7.65163 11.3082 7.59595 11.3991 7.55776C11.49 7.51963 11.5876 7.49979 11.6862 7.49941C11.7849 7.49904 11.8826 7.51808 11.9738 7.55552C12.0651 7.59291 12.1481 7.648 12.218 7.71749Z"
                    fill="#0D4273"
                  />
                </svg>
              </div>
              <p>Back</p>
            </div>
          </a>
          <div className="login-cards">
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
            <form onSubmit={formik.handleSubmit} className="login-content">
              <h1 className="text-center mb-[17px]">Log in</h1>
              <div className="login-input">
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
                <button type="submit" disabled={isLoading}>
                  {isLoading ? "Loading..." : "Log in"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

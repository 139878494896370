import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Arrows from "../assets/images/down-arrow.svg";
import Hero from "../assets/images/login-background.png";
import { useNavigate } from "react-router-dom";
import ContactUsForm from "../components/contact/ContactUsForm";
import { enqueueSnackbar } from "notistack";
import InfoService from "../services/info.service";

const ContactUs = () => {
  const [contactText, setContactText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchAboutData = async () => {
    try {
      const response = await InfoService.getContactUs();
      setContactText(response?.data?.apiresponse?.data || "");
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(
        e.response?.data?.apierror?.message || "An error occurred",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAboutData();
  }, []);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  return (
    <>
      <Header />
      <div className="hero-section bg-gradient-to-b from-[#016BCD] to-[#5BDAD5] ">
        <div className="containers">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center">
            <div className="col-span-1 p-4 lg:p-7 cards order-2 lg:order-1">
              <h1 className="m-2 text-center text-2xl">Contact Us</h1>
              <p className="m-4 text-blue-950 text-center text-base">
                {contactText}
              </p>
              <ContactUsForm />
            </div>
            <div className="col-span-1 order-1 lg:order-2">
              <div className="mb-8 flex justify-center">
                <img src={Hero} alt="Hero Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;

import axios from "axios";
import { get } from "lodash";
import config from "../config";
import serverCall from "../serverCall";

const changePassword = async (comformPassword, newPassword) => {
  try {
    const response = await serverCall.post(
      `/user/changePassword?comformPassword=${comformPassword}&newPassword=${newPassword}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

const ChangePasswordService = {
  changePassword,
};

export default ChangePasswordService;

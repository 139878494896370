import serverCall from "../serverCall";

const getImage = async () => {
  const response = await serverCall.get(`/auth/getimage`);
  return response;
};

const ImageService = {
  getImage,
};
export default ImageService;

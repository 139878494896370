import axios from "axios";
import { get } from "lodash";
import config from "../config";
import serverCall from "../serverCall";

const getAbout = async (id) => {
  const response = await serverCall.get(`/aboutus/getabout`);

  return response;
};
const getContactUs = async (id) => {
  const response = await serverCall.get(`/contactdetail/getcontact`);

  return response;
};

const getUserInformation = async (userId) => {
  const response = await serverCall.get(`/user/${userId}`);

  return response;
};

const changeUserInformation = async (body) => {
  const response = await serverCall.put(`/user/change`, body);

  return response;
};

const sendContactUs = async (data) => {
  const response = await serverCall.post(`/contact/create`, data);
  return response;
};

const InfoService = {
  getAbout,
  sendContactUs,
  getContactUs,
  getUserInformation,
  changeUserInformation,
};

export default InfoService;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../components/header/Header";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ChangePasswordService from "../services/user.service";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const confirmPassword = values?.confirmPassword;
      const newPassword = values?.newPassword;
      try {
        const response = await ChangePasswordService?.changePassword(
          confirmPassword,
          newPassword
        );
        if (response) {
          enqueueSnackbar("Password chanage successfully!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <Header />
      <div className="hero-section bg-gradient-to-b from-[#016BCD] to-[#5BDAD5]">
        <div className="containers">
          <div className="cards p-5 lg:p-10 grid gap-7 max-w-xl mx-auto w-full">
            <h1 className="text-center">Change Password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4 grid gap-1">
                <label className="font-semibold">New Password</label>
                <div className="relative">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    className="form-control pr-12"
                    {...formik.getFieldProps("newPassword")}
                  />
                  <div
                    className="absolute right-5 top-4 lg:top-[22px] cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <FaEyeSlash className="text-black/50" />
                    ) : (
                      <FaEye className="text-black/50" />
                    )}
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group mb-4 grid gap-1">
                <label className="font-semibold">Confirm Password</label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="form-control pr-12"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  <div
                    className="absolute right-5 top-4 lg:top-[22px] cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <FaEyeSlash className="text-black/50" />
                    ) : (
                      <FaEye className="text-black/50" />
                    )}
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

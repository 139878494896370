import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InfoService from "../../services/info.service";

const ContactUsForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required!"),
    phoneNumber: Yup.string().required("Phone Number is required!"),
    inquire: Yup.string().required("Inquire is required!"),
    message: Yup.string().required("Message is required!"),
    countryCode: Yup.string().required("Country Code is required!"),
  });

  const initialValues = {
    fullName: "",
    phoneNumber: "",
    inquire: "",
    message: "",
    countryCode: "",
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await InfoService.sendContactUs(values);
      console.log("Contact form response:", response);
      enqueueSnackbar("Sent details successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      resetForm();
    } catch (error) {
      console.error("Error sending message:", error);
      enqueueSnackbar(
        error.response?.data?.apierror?.message || "An error occurred",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  return (
    <div className="contact-form">
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="form-group mb-4">
          <input
            type="text"
            placeholder="Full Name"
            name="fullName"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.fullName}
            required
          />
          {formik.errors.fullName && formik.touched.fullName && (
            <p style={formerr}>{formik.errors.fullName}</p>
          )}
        </div>
        <div className="form-group mb-4">
          <PhoneInput
            inputStyle={{
              borderRadius: "10px",
              width: "100%",
              height: "auto",
              fontSize: "20px",
              border: "1px solid #0386ff",
            }}
            buttonStyle={{
              borderRadius: "10px 0px 0px 10px",
              borderLeft: "1px solid #0386ff",
              borderTop: "1px solid #0386ff",
              borderBottom: "1px solid #0386ff",
            }}
            buttonClass={{
              width: "50px",
            }}
            country={"us"}
            value={
              formik.values?.phoneNumber &&
              formik.values?.countryCode &&
              formik.values?.countryCode + formik.values?.phoneNumber
            }
            onChange={(value, data) => {
              formik.setFieldValue(
                "phoneNumber",
                value.split(data?.dialCode)[1]
              );
              formik.setFieldValue("countryCode", data.dialCode);
            }}
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <p style={formerr}>{formik.errors.phoneNumber}</p>
          )}
        </div>

        <div className="form-group mb-4">
          <input
            type="text"
            placeholder="Inquire"
            name="inquire"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.inquire}
            required
          />
          {formik.errors.inquire && formik.touched.inquire && (
            <p style={formerr}>{formik.errors.inquire}</p>
          )}
        </div>
        <div className="form-group mb-4">
          <textarea
            rows="3"
            placeholder="Message"
            name="message"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.message}
            required
          ></textarea>
          {formik.errors.message && formik.touched.message && (
            <p style={formerr}>{formik.errors.message}</p>
          )}
        </div>
        <button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? "Submitting" : "Send"}
        </button>
      </form>
    </div>
  );
};

export default ContactUsForm;

import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import AuthenticationService from "../../services/auth.service";
import { FaUserCircle } from "react-icons/fa";
import "./header.css";

const Header = () => {
  const sidebarRef = useRef();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userId");
  const email = sessionStorage.getItem("email");
  const [isOpen, setIsOpen] = useState(false);
  const [expDate, setExpDate] = useState(null);

  const [user, setUser] = useState();
  const [authorities, setAuthorities] = useState(
    sessionStorage.getItem("authorities")
  );

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleChangePassword = () => {
    // Add your change password logic here
    setIsOpen(false); // Close the dropdown after clicking
    navigate("/change-password");
  };

  const handlePreference = () => {
    // Add your preference logic here
    console.log("Preference clicked");
    setIsOpen(false); // Close the dropdown after clicking
    navigate("/preference");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onLogout = () => {
    sessionStorage.clear();
    navigate("/login");
    enqueueSnackbar("You have successfully logged out.", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      style: { maxWidth: "100px" },
    });
  };

  const fetchUserDetails = async () => {
    try {
      const response = await AuthenticationService.getUserDetails(userId);
      setExpDate(response.data.apiresponse?.data?.expiredDate);
      const newHeaders = response.data.apiresponse?.data?.authorities
        ?.slice(1, -1)
        .split(", ");
      setAuthorities(newHeaders);
      setUser(response.data.apiresponse?.data);
      sessionStorage.setItem("authorities", newHeaders);
      sessionStorage.setItem("email", response.data.apiresponse?.data?.email);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuClick = (route) => {
    if (route) {
      navigate(route);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, []);

  const getLinkClass = (path) => {
    return location.pathname === path ? "active_tab" : "a";
  };

  return (
    <>
      <div className="containers" ref={sidebarRef}>
        <nav className="bg-white w-full z-20 relative lg:pt-10 py-5">
          <div className="logo flex lg:justify-center items-center">
            <a href="/">
              <img
                src={Logo}
                className="w-[200px] h-auto lg:w-auto"
                alt="Logo"
              />
            </a>
            <div className="hidden lg:inline-block">
              {email ? (
                <>
                  <div
                    className="flex justify-end gap-2 items-center mr-[50px] absolute right-0 top-[80px] cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    <FaUserCircle className="text-28 text-primary" />
                    {email}
                  </div>
                  <div
                    ref={dropdownRef}
                    className={`
                      origin-top-right absolute right-[50px] mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
                      transition-all duration-200 ease-out transform z-50
                      ${
                        isOpen
                          ? "opacity-100 scale-y-100"
                          : "opacity-0 scale-y-0"
                      }
                    `}
                  >
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <li
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full cursor-pointer"
                        role="menuitem"
                        onClick={handleChangePassword}
                      >
                        Change Password
                      </li>
                      <li
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full cursor-pointer"
                        role="menuitem"
                        onClick={handlePreference}
                      >
                        Preference
                      </li>
                      <li
                        className="grid gap-0.5 px-4 py-2 text-sm text-gray-700 w-full border-t mt-1"
                        role="menuitem"
                      >
                        <label className="text-xs font-semibold">
                          Expire Date
                        </label>
                        {new Date(expDate)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/ /g, "-")}
                      </li>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <ul className="navigation flex flex-wrap justify-between lg:justify-center items-center mx-auto pt-0 lg:pt-10 lg:h-[88px]">
            <input type="checkbox" id="check" />

            <span className="menu flex gap-2 items-center lg:gap-7 xl:gap-[50px] [&>li>a]:text-center [&>li>a]:relative [&>li>a]:transition [&>li>a]:duration-200 [&>li>a]:ease-in-out [&>li>a]:font-medium [&>li>a]:text-lg">
              <li onClick={() => handleMenuClick("/")}>
                <a className={getLinkClass("/")}>Home</a>
              </li>
              {token && (
                <>
                  {authorities?.includes("Speech to text") && (
                    <li onClick={() => handleMenuClick("/speech-to-text")}>
                      <a className={getLinkClass("/speech-to-text")}>
                        Speech to text
                      </a>
                    </li>
                  )}
                  {authorities?.includes("Video insights") && (
                    <li onClick={() => handleMenuClick("/video-insight")}>
                      <a className={getLinkClass("/video-insight")}>
                        Video insights
                      </a>
                    </li>
                  )}
                  {authorities?.includes("Fix Hidden Characters") && (
                    <li
                      onClick={() => handleMenuClick("/fix-hidden-characters")}
                    >
                      <a className={getLinkClass("/fix-hidden-characters")}>
                        Fix Hidden Characters
                      </a>
                    </li>
                  )}
                </>
              )}
              <li onClick={() => handleMenuClick("/about")}>
                <a className={getLinkClass("/about")}>About Us</a>
              </li>
              <li onClick={() => handleMenuClick("/contact")}>
                <a className={getLinkClass("/contact")}>Contact Us</a>
              </li>
              <label htmlFor="check" className="close-menu" id="check-close">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 7.32569L22.6744 5L15 12.6744L7.32569 5L5 7.32569L12.6744 15L5 22.6744L7.32569 25L15 17.3256L22.6744 25L25 22.6744L17.3256 15L25 7.32569Z"
                    fill="black"
                  />
                </svg>
              </label>
              {user && (
                <>
                  <li
                    className="lg:hidden inline-block"
                    role="menuitem"
                    onClick={handleChangePassword}
                  >
                    <a>Change Password</a>
                  </li>
                  <li
                    className="lg:hidden inline-block"
                    role="menuitem"
                    onClick={handlePreference}
                  >
                    <a>Preference</a>
                  </li>
                </>
              )}

              {token && (
                <button
                  onClick={onLogout}
                  className="px-5 py-2.5 w-fit text-lg"
                >
                  Log Out
                </button>
              )}
              <li className="lg:hidden inline-block pt-4">
                {user && (
                  <>
                    <div className="flex justify-end gap-2 items-center text-base font-medium lowercase">
                      <FaUserCircle className="text-28 text-primary" />
                      {user?.email}
                    </div>
                  </>
                )}
              </li>
              {user && (
                <li role="menuitem" className="lg:hidden inline-block">
                  <div className="grid gap-0.5 px-4 py-2 text-sm text-gray-700 w-full mt-1 text-center">
                    <label className="text-xs font-semibold max-md:text-lg text-primary">
                      Expire Date
                    </label>
                    {new Date(expDate)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, "-")}
                  </div>
                </li>
              )}
            </span>

            <label htmlFor="check" className="open-menu">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H20M4 12H20M13 18H20"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;

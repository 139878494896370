import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Hero from "../assets/images/login-background.png";
import { useSnackbar } from "notistack";
import InfoService from "../services/info.service";

const FixHiddenCharacters = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [aboutText, setAboutText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchAboutData = async () => {
    try {
      const response = await InfoService.getAbout();
      setAboutText(response?.data?.apiresponse?.data?.text || "");
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(
        e.response?.data?.apierror?.message || "An error occurred",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAboutData();
  }, []);
  return (
    <>
      <Header />
      <div className="hero-section bg-gradient-to-b from-[#016BCD] to-[#5BDAD5]">
        <div className="containers">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-start ">
            <div className="col-span-1 p-5 lg:p-10 order-2 lg:order-1">
              <h1 className="text-white">Fix Hidden Characters</h1>
              {isLoading ? (
                <p className="pt-4 text-white text-[18px]">Loading...</p>
              ) : (
                <p className="pt-4 text-white text-[18px]">{aboutText}</p>
              )}
            </div>
            <div className="col-span-1 order-1 lg:order-2">
              <div className="mb-8 flex justify-center">
                <img src={Hero} alt="Hero Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FixHiddenCharacters;

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LogIn from "./authentication/login/page";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import FixHiddenCharacters from "./pages/FixHiddenCharacters";
import MainHome from "./pages/MainHome";
import SpeechToText from "./pages/SpeechToText";
import VideoInsights from "./pages/VideoInsights";
import store from "./store";
import ChangePassword from "./pages/ChangePassword";
import Preference from "./pages/Preference";

export default function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE}>
      <Provider store={store}>
        <Routes>
          <Route index path="/login" element={<LogIn />} />
          <Route path="/" element={<MainHome />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/speech-to-text" element={<SpeechToText />} />
          <Route path="/video-insight" element={<VideoInsights />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/preference" element={<Preference />} />
          <Route
            path="/fix-hidden-characters"
            element={<FixHiddenCharacters />}
          />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

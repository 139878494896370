import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { enqueueSnackbar } from "notistack";
import InfoService from "../services/info.service";

const Preference = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userId = sessionStorage.getItem("userId");
  const [initialData, setInitialData] = useState({
    compnyName: "",
    phoneNumber: "",
    countryCode: "",
  });
  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };

  const validationSchema = Yup.object().shape({
    compnyName: Yup.string().required("Company Name is required!"),
    phoneNumber: Yup.string().required("Phone Number is required!"),
    countryCode: Yup.string().required("Country Code is required!"),
  });

  const initialValues = {
    compnyName: "",
    phoneNumber: "",
    countryCode: "",
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    const modifiedData = {};
    Object.keys(values).forEach((key) => {
      if (values[key] !== initialData[key]) {
        modifiedData[key] = values[key];
      }
    });

    if (Object.keys(modifiedData).length > 0) {
      try {
        const response = await InfoService.changeUserInformation(modifiedData);
        if (response) {
          enqueueSnackbar("Information updated successfully", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          resetForm();
          getUserInformation();
        }
      } catch (error) {
        enqueueSnackbar(
          error.response?.data?.apierror?.message || "An error occurred",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          }
        );
      }
    } else {
      enqueueSnackbar("No changes detected", { variant: "info" });
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit,
  });

  const getUserInformation = async () => {
    try {
      const userData = await InfoService?.getUserInformation(userId);
      const data = userData?.data?.apiresponse?.data;
      if (data) {
        setInitialData({
          compnyName: data?.compnyName || "",
          phoneNumber: data?.phoneNumber || "",
          countryCode: data?.countryCode || "",
        });
        formik.setFieldValue("compnyName", data?.compnyName);
        formik.setFieldValue("phoneNumber", data?.phoneNumber);
        formik.setFieldValue("countryCode", data?.countryCode);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserInformation();
    }
  }, []);
  return (
    <>
      <Header />
      <div className="hero-section bg-gradient-to-b from-[#016BCD] to-[#5BDAD5]">
        <div className="containers">
          <div className="cards p-5 lg:p-10 grid gap-7 max-w-xl mx-auto w-full">
            <h1 className="text-center">User Information</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4 grid gap-1">
                <label className="font-semibold">Company Name</label>
                <input
                  type="text"
                  placeholder="Company Name"
                  name="compnyName"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.compnyName}
                  required
                />
                {formik.errors.compnyName && formik.touched.compnyName && (
                  <p style={formerr}>{formik.errors.compnyName}</p>
                )}
              </div>
              <div className="form-group mb-4 grid gap-1">
                <label className="font-semibold">Mobile Number</label>
                <PhoneInput
                  inputStyle={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "auto",
                    fontSize: "20px",
                    border: "1px solid #0386ff",
                  }}
                  buttonStyle={{
                    borderRadius: "10px 0px 0px 10px",
                    borderLeft: "1px solid #0386ff",
                    borderTop: "1px solid #0386ff",
                    borderBottom: "1px solid #0386ff",
                  }}
                  buttonClass={{
                    width: "50px",
                  }}
                  country={"us"}
                  value={
                    formik.values?.phoneNumber &&
                    formik.values?.countryCode &&
                    formik.values?.countryCode + formik.values?.phoneNumber
                  }
                  onChange={(value, data) => {
                    formik.setFieldValue(
                      "phoneNumber",
                      value.split(data?.dialCode)[1]
                    );
                    formik.setFieldValue("countryCode", data.dialCode);
                  }}
                />
                {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                  <p style={formerr}>{formik.errors.phoneNumber}</p>
                )}
              </div>
              <button type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Loading..." : "Update"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preference;

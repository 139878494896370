import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Arrows from "../assets/images/down-arrow.svg";
import Hero from "../assets/images/login-background.png";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import ImageService from "../services/image.service";

const MainHome = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const companyName = sessionStorage.getItem("compnyName");
  const [authorities, setAuthorities] = useState(
    sessionStorage.getItem("authorities")
  );
  const [selectedImage, setSelectedImage] = useState(Hero);

  const getImage = async () => {
    try {
      const response = await ImageService.getImage();
      setSelectedImage(response.data.apiresponse.data.image);
    } catch (error) {
      enqueueSnackbar(error.response.data.apiresponse.message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <>
      <Header />
      <div className="hero-section bg-gradient-to-b from-[#016BCD] to-[#5BDAD5]">
        <div className="containers">
          {!token ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center">
              <div className="col-span-1 p-5 lg:p-10 cards order-2 lg:order-1">
                <h1 className="text-center mb-10">
                  Generate subtitle in 3 steps 
                </h1>
                <button className="bg-lightBlue hover:bg-lightBlue/95 btns text-lg lg:text-2xl xl:text-28 p-3 md:p-5">
                  Upload your audio/video
                </button>
                <div className="flex justify-center p-2 h-14 w-14 lg:h-16 lg:w-16 xl:h-20 xl:w-20 mx-auto">
                  <img src={Arrows} alt="Arrow" />
                </div>
                <button className="bg-lightBlue hover:bg-lightBlue/95 btns text-lg lg:text-2xl xl:text-28 p-3 md:p-5">
                  Click Generate
                </button>
                <div className="flex justify-center p-2 h-14 w-14 lg:h-16 lg:w-16 xl:h-20 xl:w-20 mx-auto">
                  <img src={Arrows} alt="Arrow" />
                </div>
                <button className="bg-lightBlue hover:bg-lightBlue/95 btns  text-lg lg:text-2xl xl:text-28 p-3 md:p-5">
                  Download Subtitles
                </button>
              </div>
              <div className="col-span-1 order-1 lg:order-2">
                <div className="mb-8 flex justify-center">
                  <img src={selectedImage} alt="Hero Image" />
                </div>
                <div className="md:w-3/4 mx-auto">
                  <button
                    onClick={() => navigate("/login")}
                    className="text-lg md:text-28 p-3 md:p-5"
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-6 lg:gap-14 ">
              <div className="grid gap-4">
                <h1 className="text-4xl lg:text-5xl xl:text-8xl text-center font-medium text-white w-fit mx-auto border-b-4 border-white">
                  Welcome {companyName}
                </h1>
                <h3 className="text-4xl lg:text-5xl xl:text-7xl font-medium text-center text-white">
                  Lets Generate
                </h3>
              </div>
              <div>
                {authorities?.includes("Speech to text") ||
                authorities?.includes("Video insights") ||
                authorities?.includes("Fix Hidden Characters") ? (
                  <div className="flex max-sm:flex-col justify-center items-center gap-4 mt-10 max-w-6xl mx-auto w-full">
                    {authorities?.includes("Speech to text") && (
                      <a href="/speech-to-text" className="w-full max-w-80">
                        <button className="bg-white text-primary rounded-50 duration-200 w-full max-w-80 hover:text-white">
                          Speech to Text
                        </button>
                      </a>
                    )}
                    {authorities?.includes("Video insights") && (
                      <a href="/video-insight" className="w-full max-w-80">
                        <button className="bg-white text-primary rounded-50 duration-200 w-full max-w-80 hover:text-white">
                          Video Insights
                        </button>
                      </a>
                    )}
                    {authorities?.includes("Fix Hidden Characters") && (
                      <a
                        href="/fix-hidden-characters"
                        className="w-full max-w-80"
                      >
                        <button className="bg-white text-primary rounded-50 duration-200 w-full max-w-80 hover:text-white">
                          Fix Hidden Characters
                        </button>
                      </a>
                    )}
                  </div>
                ) : (
                  <p className="text-lg lg:text-xl font-medium text-center text-white">
                    Sorry you dont have permmision
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MainHome;
